import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/_services/http.service'

const API_EXT = '/'
@Injectable({
  providedIn: 'root'
})
export class CheckhealthService {

  env = environment;
  api_url: string = environment.apiUrl + API_EXT;

  constructor(
    private http: HttpClient,
    private headHttp:HttpService) { }

  api_check(): Observable<any> {
    return this.http.get(this.api_url + 'healthchecker').pipe(
      timeout(1000),
      map((response: any) => {
        return response;
      })
    );
  }
}
