import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/_services/auth.service';
import { StorageService } from 'src/_services/storage.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule],

  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  @Input() isLoggedInM: boolean = false;


  nameControl = new FormControl('', [Validators.required, Validators.minLength(5)]);
  passwordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);


  form = new FormGroup({
    username: this.nameControl,
    password: this.passwordControl
  });




  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router) { }

  ngOnInit(): void {
    this.isLoggedIn = this.isLoggedInM
    console.log(this.isLoggedIn)
  }

  onSubmit(): void {
    console.log(this.form)
    if (this.form.valid) {
      const username = this.form.get('username')?.value
      const password = this.form.get('password')?.value

      if (username && password) {


        this.authService.login(username, password).subscribe({
          next: (data: any) => {
            this.storageService.saveUser(data);
            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.router.navigateByUrl('/campaign').then(() => {
              window.location.reload();
            });
          },
          error: (err: { error: { message: string; }; }) => {
            this.errorMessage = err.error.message;
            this.isLoginFailed = true;
          }
        });
      }

    }
  }

}
