<div class="row" *ngIf="!isLoggedIn">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="blocNameTitle">
                    <div class="bloc1" style="cursor: pointer;">SANEI</div>
                    <div class="bloc2" style="cursor: pointer;">NLU & Play</div>
                </div>
            </div>
        </div>
        <div class="card card-container">
            <div class="blocLog">
                <form
                [formGroup]="form"
                (submit)="onSubmit()"
              >
                <div class="form-group  marginTB10">
                  <label for="username">Identifiant</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="username"
                    name="username"
                    required
                  />
                </div>
                <div class="form-group marginTB10">
                  <label for="password">Mot de passe</label>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="password"
                    name="password"
                    required
                    minlength="6"
                  />
                </div>
                <div class="form-group marginTB10">
                  <button class="btn btn-primary btn-block">
                    Identifiant
                  </button>
                </div>
                <div class="form-group">
                </div>
              </form>
            </div>

        </div>
      </div>
</div>