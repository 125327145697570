import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_EXT = '/user/'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  env = environment;
  api_url: string = environment.apiUrl + API_EXT;

  constructor(private http: HttpClient) { }

  getMe(): Observable<any> {
    return this.http.get(this.api_url + 'me', { responseType: 'json' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(this.api_url, { responseType: 'json' });
  }
  
  getModeratorBoard(): Observable<any> {
    return this.http.get(this.api_url + 'mod', { responseType: 'json' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(this.api_url + 'admin', { responseType: 'json' });
  }

  getUserInfo(id:any): Observable<any> {
    return this.http.get(this.api_url + id, { responseType: 'json' });
  }

  updateUser(id:any, data:any): Observable<any> {
    return this.http.put(this.api_url + id,data, { responseType: 'json' });
  }

  updateUserPassword(id:any, data:any): Observable<any> {
    return this.http.put(this.api_url + id + '/password',data, { responseType: 'json' });
  }

  getUserDelete(id:any): Observable<any> {
    return this.http.delete(
      this.api_url+id, httpOptions);
  }

  register(username:string, firstname:string, lastname:string, password:string): Observable<any> {
    return this.http.post(
      this.api_url + 'create',
      {
        'username':username,
        'firstname':firstname,
        'lastname':lastname,
        'password':password,
        'customer_id':1
      },
      httpOptions
    );
  } 
}
