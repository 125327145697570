import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component'


const routes: Routes = [
  { path: 'home',     loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'corpus',     loadChildren: () => import('./corpus/corpus.module').then(m => m.CorpusModule) },
  { path: 'project',     loadChildren: () => import('./project/project.module').then(m => m.ProjectModule) },
  { path: 'customer',     loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
  { path: 'user',     loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'model',     loadChildren: () => import('./model/model.module').then(m => m.ModelModule) },
  { path: 'login', 
    component: LoginComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
