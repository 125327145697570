import { OnInit, Component } from  '@angular/core';
import { Router } from '@angular/router';
import { CheckhealthService} from 'src/_services/checkhealth.service'
import { RouterLink } from '@angular/router';
import { StorageService } from 'src/_services/storage.service';
import { UserService } from 'src/_services/user.service';
import { EventBusService } from 'src/_shared/event-bus.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Sanei NLU';
  private todoslength : number =0;
  checkheath=true
  isLoggedIn = false;
  username: string = "";
  userId:number =0;
  eventBusSub?: Subscription;

  lastpubli=environment.lastPubli

  constructor(
    private checkhealthService:CheckhealthService,
    private storageService: StorageService,
    private userService:UserService,
    private eventBusService: EventBusService,
    private router: Router,
  ) {


  }

  ngOnInit() {
    this.isLoggedIn = false
    this.checkhealthService.api_check().subscribe(
      {
        next: (value: any) => { 
          this.checkheath=true
        },
        error: (error: any) => { 
          this.checkheath=false
        },
         complete: () => { }
      }
    );

    this.isLoggedIn = this.storageService.isLoggedIn();
    if (this.isLoggedIn) {


      this.userService.getMe().subscribe({
        next: (data: any) => {
          this.storageService.saveInfoUser(data);
          this.username = data['username']
          this.userId = data['id']
        },
        error: (err: { error: { message: string; }; }) => {
        }
      });

    } else {
      this.router.navigate(['/login'])
    }

    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });

}

  logout(): void {
    this.isLoggedIn = false;
    this.storageService.clean();
    this.router.navigateByUrl('/login').then(() => {
      window.location.reload();
    });
  }

  tobeLogged(component:any){
    component.someProperty = 'someValue';
  }
}
