<div class="container" *ngIf="isLoggedIn">
  <div class="row">
    <div class="menu">
      <div class="blocName" routerLink="./"  *ngIf="isLoggedIn">
        <div class="bloc1" style="cursor: pointer;">SANEI</div>
        <div class="bloc2" style="cursor: pointer;">NLU & Play</div>
      </div>
      <nav  *ngIf="isLoggedIn">
        <ul>
          <li [routerLink]="['corpus']"  routerLinkActive="active-link" class="fakeCursor">Corpus</li>
          <li [routerLink]="['model']"  routerLinkActive="active-link"  class="fakeCursor">Modèles</li>
          <li [routerLink]="['project']"  routerLinkActive="active-link"  class="fakeCursor">Projet</li>
          <li [routerLink]="['customer/home']"  routerLinkActive="active-link" class="fakeCursor">Client</li>
          <li [routerLink]="['user/home']"  routerLinkActive="active-link" class="fakeCursor">Utilisateur</li>
        </ul>
      </nav>

      <nav class="" style="float: right;">

        <ul class="navbar-nav ml-auto" *ngIf="!isLoggedIn">
          <li class="nav-item">
            <a href="/login" class="nav-link" routerLink="login">Connexion</a>
          </li>
        </ul>
    
        <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn">
          <li [routerLink]="['/user/edit/', this.userId]">
            <span class="identif">{{ username }}</span>
          </li>
          <li class="cursorLink" (click)="logout()">
            Deconnexion
          </li>
        </ul>

      </nav>

    </div>

  </div>
</div>

<div class="container page" >
    <router-outlet (isLoggedIn)="isLoggedIn"></router-outlet>
</div>

<div class="footer">
  <div *ngIf="checkheath" class="">
  </div>

  <div>
    {{lastpubli}}
  </div>
</div>
<div *ngIf="!checkheath" class="error">
  Erreur d'API : Le serveur d'API ne répond pas... <br />
  Vous pouvez rafraîchir la page<br />
  Si le problème persiste, merci de contacter le support en production
</div>